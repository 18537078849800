.settings {
    background: rgba(0, 0, 0, 0.3);
    width: max-content;
    display:block;
    padding: 0.7rem 1.7rem;
    z-index: 2;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    top: 2rem;
    display: flex;
    gap: 0.8rem;
    border-radius: 3rem;
    backdrop-filter: blue(15px);
}
.settings a {
    background: transparent;
    padding: 0.9rem;
    border-radius: 50%;
    display: flex;
    color: var(--color-light);
    font-size: 1.1rem;
}
.settings a:hover {
    background: rgba(0, 0, 0, 0.3);
}
.settings a.active {
    background: var(--color-primary);
    color: var(--color-bg);
}
.settings-icon {
    margin-top: 5px;
}
.settings audio {
    display: none;
}