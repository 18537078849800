header {
    height: 100vh;
    padding-top: 1rem;
    overflow: hidden;
}
.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

/* ========== NAME TITLE ========== */
.name-title {
    font-family: 'Hubballi';
}


/* ========== CTA ========== */
.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/* ========== HEADER SOCIALS ========== */
.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}
.header__socials::after {
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}
/* ========== ME ========== */
.me {
    background: linear-gradient(var(--color-primary), transparent);
    width: 22rem;
    height: 30rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 5rem 1.5rem 1.5rem 1.5rem;
}
.me img {
    position: relative;
    bottom: 3rem;
    left: 1.5rem;
    width: 80%;
}
/* ========== SCROLL DOWN ========== */
.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 6rem;
    font-weight: 300;
    font-size: 0.9rem;
}
.scroll__down span {
  position: absolute;
  top: 0;
  left: 50%;
  width: 30px;
  height: 50px;
  border: 2px solid #fff;
  border-radius: 50px;
  box-sizing: border-box;
}
.scroll__down span::before {
  position: absolute;
  top: 10px;
  left: 50%;
  content: '';
  width: 6px;
  height: 6px;
  margin-left: -3px;
  background-color: #fff;
  border-radius: 100%;
  box-sizing: border-box;
}
.scroll__down span::after {
  position: absolute;
  bottom: -18px;
  left: 50%;
  width: 18px;
  height: 18px;
  content: '';
  margin-left: -9px;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  box-sizing: border-box;
}

/* ========== MEDIA QUERIES (MEDIUM DEVICES) ========== */

@media screen and (max-width: 1024px) {
    header {
        height: 68vh;
    }
}

/* ========== MEDIA QUERIES (SMALL DEVICES) ========== */

@media screen and (max-width: 600px) {
    header {
        height: 100vh;
    }
    .header__socials, .scroll__down {
        display: none;
    }
}